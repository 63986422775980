@use "@angular/material" as mat;
@use "@angular/cdk" as cdk;
// Plus imports for other components in your app.

@include mat.core();

// Custom colors derived from Portal Style Guide 2021. TODO: Reference tailwind's colors directly here for consistency
$acm-portal-blue: (
  50: #e2ebf9,
  100: #b6cdf1,
  200: #86ace7,
  300: #558bdd,
  400: #3072d6,
  500: #0c59cf,
  600: #0a51ca,
  700: #0848c3,
  800: #063ebd,
  900: #032eb2,
  A100: #86ace7,
  A200: #0c59cf,
  A400: #0848c3,
  A700: #032eb2,
  contrast: (
    50: #5c5c69,
    100: #5c5c69,
    200: #5c5c69,
    300: #5c5c69,
    400: #5c5c69,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #5c5c69,
    A200: #5c5c69,
    A400: #5c5c69,
    A700: #5c5c69
  )
);

// Custom colors derived from Portal Style Guide 2021. TODO: Reference tailwind's colors directly here for consistency
$acm-portal-red: (
  50: #fbeaea,
  100: #f5cbca,
  200: #efa8a6,
  300: #e98582,
  400: #e46b68,
  500: #df514d,
  600: #db4a46,
  700: #d7403d,
  800: #d23734,
  900: #ca2725,
  A100: #f5cbca,
  A200: #df514d,
  A400: #d7403d,
  A700: #ca2725,
  contrast: (
    50: #5c5c69,
    100: #5c5c69,
    200: #5c5c69,
    300: #5c5c69,
    400: #5c5c69,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #5c5c69,
    A200: #5c5c69,
    A400: #5c5c69,
    A700: #5c5c69
  )
);

$acm-portal-primary: mat.m2-define-palette($acm-portal-blue, A200, A100, A400);
$acm-portal-accent: mat.m2-define-palette($acm-portal-blue, A200, A100, A400);

// The warn palette is optional (defaults to red).
$acm-portal-warn: mat.m2-define-palette(mat.$m2-red-palette, A200, A100, A400);

// Change default Angular mat font from Roboto > Rawline
$typography: mat.m2-define-legacy-typography-config(
  $font-family: "rawline"
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$acm-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $acm-portal-primary,
      accent: $acm-portal-accent,
      warn: $acm-portal-warn
    ),
    typography: $typography
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($acm-portal-theme);

html {
  //Mat checkbox
  .mat-mdc-checkbox {
    &.mat-mdc-checkbox-disabled {
      @apply opacity-50;
      .mdc-checkbox__background {
        @apply bg-gray-lighter;
      }
    }
    .mdc-label {
      @apply text-sm font-medium text-gray-dark dark:text-gray-lighter;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
      @apply text-white;
    }

    //override variable to control custom size
    --mdc-checkbox-state-layer-size: 35px;
  }
}

//Mat checkbox
.mat-checkbox {
  &.mat-checkbox-disabled {
    @apply opacity-50;
    .mat-checkbox-background {
      @apply bg-gray-lighter;
    }
  }

  .mdc-label {
    @apply text-sm font-medium text-gray-dark dark:text-gray-lighter;
  }

  .mat-checkbox-layout {
    @apply whitespace-normal;
    .mat-checkbox-label {
      @apply leading-normal;
    }
  }
}

//Mat button
.mat-button-focus-overlay {
  @apply bg-blue-lighter opacity-25;
}

[mat-button].mat-button {
  @apply text-gray-dark font-bold text-sm leading-snug;
}

[mat-icon-button].mat-icon-button {
  @apply flex justify-center items-center;
}

// Mat button toggle
mat-button-toggle-group {
  &.button-toggle-group {
    @apply border border-solid border-gray-lighter h-36 text-sm font-bold;

    &:not([disabled]):not(.disabled) {
      .mat-button-toggle {
        @apply bg-blue-lightest text-blue-dark;
        &.mat-button-toggle-checked {
          @apply bg-blue-dark text-white;
        }
        &.is-primary {
          @apply bg-blue-lightest text-blue-dark;
          &.mat-button-toggle-checked {
            @apply bg-blue-dark text-white;
          }
        }
        &.is-warning,
        &.is-alert {
          @apply bg-orange-lightest text-orange;
          &.mat-button-toggle-checked {
            @apply bg-orange text-white;
          }
        }
        &.is-error {
          @apply bg-red-lightest text-red;
          &.mat-button-toggle-checked {
            @apply bg-red text-white;
          }
        }
      }
    }
    .mat-button-toggle {
      @apply ring-0;
      &:focus {
        @apply ring-0;
      }
    }
  }
}

//Mat select & mat menu
mat-select.select {
  @apply font-medium text-base;
  .mat-select-trigger {
    @apply h-full;
  }

  .mat-mdc-select-trigger {
    @apply w-full h-full px-5;
  }

  .mat-mdc-select-value {
    @apply align-middle;
  }
}

.mat-select-panel-wrap {
  .mat-select-panel {
    @apply p-10;

    .mat-option {
      @apply rounded;
    }
  }
}
.mat-autocomplete-panel {
  @apply flex flex-col gap-y-5 p-10;
  mat-option.mat-option {
    @apply text-gray-dark text-base leading-tight h-auto min-h-48 rounded py-10 flex-none;
  }
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  @apply bg-blue-lighter bg-opacity-25;
}

.mat-option.mat-active {
  @apply text-blue-dark bg-blue-lighter bg-opacity-25;
}

// Mat Menu
.mat-mdc-menu-panel {
  @apply rounded-lg text-gray-dark dark:text-gray-lighter dark:bg-gray-darker;

  .mat-menu-item:hover:not([disabled]),
  .mat-menu-item.cdk-program-focused:not([disabled]),
  .mat-menu-item.cdk-keyboard-focused:not([disabled]),
  .mat-menu-item-highlighted:not([disabled]) {
    @apply bg-blue-lighter bg-opacity-25;
  }

  .mat-menu-content:not(:empty),
  .mat-mdc-menu-content {
    @apply flex flex-col p-10 gap-y-5;
  }

  .mat-mdc-menu-content,
  .mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
    @apply text-left text-sm font-bold leading-normal;
  }

  .mat-mdc-menu-item .mat-icon-no-color,
  .mat-mdc-menu-item .mat-mdc-menu-submenu-icon {
    @apply text-gray;
  }
  .mat-mdc-menu-item:visited,
  .mat-mdc-menu-item:link {
    @apply text-gray-dark dark:text-gray-lighter;
  }
}

//Mat form item
.mat-form-field {
  .mat-form-field-underline {
    @apply bg-gray-light;
  }
}

//Mat snack bar
.mat-snack-bar-container {
  @apply text-gray-dark font-semibold bg-white;
  &.is-success {
    @apply text-green;
  }
  &.is-warning,
  &.is-error {
    @apply text-red;
  }
}

//Mat tooltip
.mat-tooltip {
  white-space: pre-line !important;
}

//Mat dialogs
mat-dialog-container.mat-mdc-dialog-container {
  @apply text-base font-body p-0 text-gray-dark flex overflow-hidden rounded-lg max-w-full max-h-96vh;

  & > * {
    @apply w-full max-h-full;
  }

  .dialog-toolbar {
    @apply min-h-0 bg-white text-gray-dark relative flex flex-col flex-grow-0 p-20 pb-0 border-b border-solid border-gray-lighter;
  }

  .dialog-content {
    @apply flex flex-col flex-grow overflow-auto h-full p-10 md:p-20;
    max-height: inherit;
  }

  header {
    @apply flex items-center flex-none p-10 md:p-20 border-b border-solid border-gray-lighter;
  }
  footer {
    @apply flex flex-none p-10 md:p-20 mt-auto border-t border-solid border-gray-lighter;
  }
}

//Sidenav & drawers
.mat-drawer-container {
  @apply text-gray-dark dark:text-white dark:bg-gray-darkest bg-gray-lightest;
}

.mat-drawer {
  @apply bg-white dark:bg-gray-darker text-gray-dark dark:text-white;
}

//Mat expansion panel & accordion
.mat-expansion-panel {
  .mat-expansion-panel-body {
    @apply p-0;
  }
}

// Mat button toggle
mat-button-toggle-group {
  @apply border border-solid border-gray-lighter h-34;

  .mat-button-toggle {
    @apply text-xs font-bold uppercase;
  }

  &:not([disabled]):not(.disabled) {
    .mat-button-toggle {
      @apply bg-white text-gray-dark;
      &.mat-button-toggle-checked {
        @apply bg-blue-dark text-white;
      }
      &.is-primary {
        @apply bg-white text-blue-dark;
        &.mat-button-toggle-checked {
          @apply bg-blue-dark text-white;
        }
      }
      &.is-warning {
        @apply bg-red-lightest text-red;
        &.mat-button-toggle-checked {
          @apply bg-red text-white;
        }
      }
    }
  }

  .mat-button-toggle {
    @apply ring-0;
    &:focus {
      @apply ring-0;
    }
    .mat-button-toggle-button {
      @apply min-w-64;
      .mat-button-toggle-label-content {
        line-height: 3.6rem;
      }
    }
  }
}

.mat-datepicker-content {
  button {
    @apply min-w-0;
  }
}

.mat-datepicker-content.mat-accent .mat-calendar-body-selected,
.mat-datepicker-content.mat-primary .mat-calendar-body-selected {
  @apply text-white;
}
