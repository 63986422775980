// buttons
button,
.button,
mat-button.button, .mdc-button.button {
  @apply items-center h-34 min-w-128 text-sm font-bold uppercase px-10 m-0 no-underline leading-snug rounded transition duration-150;

  &:disabled,
  &[disabled],
  &.disabled,
  &.mat-button-disabled {
    @apply bg-gray-lighter text-gray cursor-not-allowed;
  }

  &:not([disabled]):not(.disabled):not(:disabled) {
    @apply cursor-pointer;
    &:hover {
      @apply bg-blue-lighter bg-opacity-25;
    }

    &:focus {
      &:not(.mat-mdc-menu-item):not(.is-tab) {
        @apply ring;
      }
    }
  }

  &.icon-button {
    @apply p-0 h-34 w-34 leading-none min-w-34 min-h-0;

    &.is-lg {
      @apply p-0 w-42 h-42 min-w-42;
    }

    &.is-wide {
      @apply p-0 w-48 min-w-48;
    }

    &.is-sm {
      @apply p-0 w-28 h-28 min-w-28;
    }
  }

  &.is-xl {
    @apply px-30;
    height: 4.2rem;
    min-width: 17.2rem;
  }

  &.is-lg {
    @apply px-20;
    height: 4.2rem;
    min-width: 12.8rem;
    line-height: 1;
  }

  &.is-sm {
    height: 2.8rem;
    min-width: 6.4rem;
  }

  &.is-primary {
    &:not([disabled]):not(.disabled) {
      &:not(.is-inverted) {
        @apply bg-blue-dark;
        @apply text-white;

        &:hover {
          @apply bg-blue;
        }
      }

      &.is-outlined {
        @apply bg-transparent;
        @apply text-blue-dark;
        @apply border;
        @apply border-solid;
        @apply border-blue-dark;

        &:hover {
          @apply text-blue;
          @apply border-blue;
          @apply bg-blue-lighter;
          @apply bg-opacity-25;
        }
      }

      &.is-inverted {
        @apply bg-transparent;
        @apply text-blue-dark;

        &:hover {
          @apply text-blue;
          @apply bg-blue-lighter;
          @apply bg-opacity-25;
        }
      }
    }
  }

  &.is-alert {
    &:not([disabled]):not(.disabled) {
      &:not(.is-inverted) {
        @apply bg-orange;
        @apply text-white;

        &:hover {
          @apply bg-orange;
        }
      }

      &.is-outlined {
        @apply bg-transparent;
        @apply text-orange;
        @apply border;
        @apply border-solid;
        @apply border-orange;

        &:hover {
          @apply text-orange-dark;
          @apply border-orange-dark;
          @apply bg-orange-lighter;
          @apply bg-opacity-25;
        }
      }

      &.is-inverted {
        @apply bg-transparent;
        @apply text-orange;

        &:hover {
          @apply text-orange-dark;
          @apply bg-orange-lighter;
          @apply bg-opacity-25;
        }
      }
    }
  }

  &.is-warning {
    &:not([disabled]):not(.disabled) {
      &:not(.is-inverted) {
        @apply bg-red;
        @apply text-white;

        &:hover {
          @apply bg-red;
        }
      }

      &.is-outlined {
        @apply bg-transparent;
        @apply text-red;
        @apply border;
        @apply border-solid;
        @apply border-red;

        &:hover {
          @apply text-red-dark;
          @apply border-red-dark;
          @apply bg-red-lighter;
          @apply bg-opacity-25;
        }
      }

      &.is-inverted {
        @apply bg-transparent;
        @apply text-red;

        &:hover {
          @apply text-red-dark;
          @apply bg-red-lighter;
          @apply bg-opacity-25;
        }
      }
    }
  }

  &.is-grey-dark {
    &.is-inverted {
      &:not([disabled]):not(.disabled) {
        @apply text-gray-dark;
        @apply bg-transparent;

        &:hover {
          @apply text-gray-darker;
          @apply bg-blue-lightest;
          @apply bg-opacity-50;
        }
      }
    }
  }

  &.is-white {
    &:not([disabled]):not(.disabled) {
      &:not(.is-inverted) {
        @apply text-gray-dark;
        @apply bg-white;

        &:hover {
          @apply bg-gray-lighter;
        }
      }

      &.is-inverted {
        @apply text-white;
        @apply bg-transparent;

        &:hover {
          @apply bg-blue-lightest;
          @apply bg-opacity-25;
        }
      }
    }
  }

  &.is-success {
    &:not([disabled]):not(.disabled) {
      &:not(.is-inverted) {
        @apply text-white;
        @apply bg-green;

        &:hover {
          @apply bg-green-dark;
        }
      }

      &.is-outlined {
        @apply bg-transparent;
        @apply text-green;
        @apply border;
        @apply border-solid;
        @apply border-green;

        &:hover {
          @apply text-green-dark;
          @apply border-green-dark;
          @apply bg-green-lighter;
          @apply bg-opacity-25;
        }
      }

      &.is-inverted {
        @apply text-green;
        @apply bg-transparent;

        &:hover {
          @apply text-green-dark;
          @apply bg-green-lightest;
        }
      }
    }
  }

  &.is-tab {
    @apply rounded-b-none border-b transition-colors duration-150;

    &:not(.active) {
      @apply border-transparent;
    }

    &.active {
      @apply text-blue-dark border-blue-dark;
    }
  }
}

// inputs
.input,
.select,
.textarea {
  @apply font-medium rounded transition duration-150 outline-none no-underline tracking-wide bg-none border border-solid bg-white dark:bg-black min-w-96 leading-normal text-base m-0 px-5 py-0;
  text-shadow: none;

  &:not(textarea) {
    @apply h-34;
    text-align: left!important;
  }

  &:not([disabled]):not(.disabled):not(.mat-select-disabled):not(:disabled) {
    @apply text-gray-dark border border-solid border-gray-light dark:text-white dark:border-gray;

    &:focus {
      @apply ring;
    }

    &.is-inverted {
      @apply border-transparent bg-white dark:bg-gray-darker;
    }

    &.ng-touched.ng-invalid {
      @apply bg-red-lighter bg-opacity-25 border-red;
    }
  }

  &[disabled],
  &.disabled,
  &:disabled,
  &.mat-select-disabled {
    @apply bg-gray-lighter text-gray-darkest border-gray opacity-50 bg-opacity-50 cursor-not-allowed;
  }

  &.is-lg {
    @apply h-42;
  }

  &.is-sm {
    @apply h-28;
  }
}

.input-error-message {
  @apply text-red text-xs absolute mt-0 top-0 left-0 w-full pt-2;
}

//datepicker w/ icon
.datepicker-input-container {
  @apply relative;
  .datepicker-input {
    @extend .input;
    @apply pr-40 w-full;
  }
  .datepicker-icon {
    @apply absolute right-10 transform top-1/2 -translate-y-1/2 mi-18 text-gray;
  }
}
